import { render, staticRenderFns } from "./PlanChangeStep2.vue?vue&type=template&id=005c4054&scoped=true&"
import script from "./PlanChangeStep2.vue?vue&type=script&lang=js&"
export * from "./PlanChangeStep2.vue?vue&type=script&lang=js&"
import style0 from "./PlanChangeStep2.vue?vue&type=style&index=0&id=005c4054&lang=stylus&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "005c4054",
  null
  
)

export default component.exports