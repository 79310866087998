<template>
  <div class="plan-change-step2-container">
    <div class="header">
      <div class="flex-align unselect" style="gap:0 12px" @click="prev">
        <i class="material-icons">keyboard_arrow_left</i>
        <div class="body4">이전단계</div>
      </div>
      <div class="h5">{{ selectedItem.membership }}</div>
    </div>
    <div class="flex-justify">
      <div class="plan-content">
        <!-- 결제주기 -->
        <div class="h8">결제주기</div>
        <div v-if="remainMonth>0 && service.plan.membership !== selectedItem.membership"
             class="box-blue flex-align" style="gap:16px;margin-bottom:16px">
          <i class="material-icons-round">warning</i>
          <div>현재 플랜의 잔여기간이 {{ remainMonth }}개월 남았습니다.<br><b>{{ selectedItem.subscriptions.filter(i => i.date_unit >= remainMonth)[0].date_unit }}개월 이상 플랜부터 선택할 수 있습니다.</b></div>
        </div>
        <div class="event" v-if="service.plan.trial">
          <span class="tag-event">EVENT</span>
          무료체험 기간 내 3,6,12개월 플랜 선택 및 결제 시 혜택 제공!
        </div>
        <div>
          <div v-for="(sitem,sidx) in selectedItem.subscriptions" :key="`subscribe-${sidx}`"
               class="item-bar unselect"
               :class="{'item-bar-selected': sitem.date_unit === value.date_unit,
                        'item-bar-disabled': sitem.disabled,
                        'current-bar': isCurrent(sitem)}"
               @click="clickDateUnit(sitem)">
            <div class="flex-align">
              <div class="radio-button"></div>
              <div class="body4 bar-name">{{ dateUnitTxt(sitem) }} <span v-if="isCurrent(sitem)" class="tag-use">이용중</span></div>
            </div>
            <div style="text-align: right">
              <span v-if="sitem.discount_rate>0" class="line-through">{{ sitem.org_price|currency }}</span>
              <span v-if="sitem.discount_rate > 0"
                    class="price primary" style="margin:0 4px">{{ sitem.discount_rate }}%</span>
              <span class="price">{{ sitem.price|currencyNum }}</span><span>원</span>
              <span v-if="sitem.date_unit>1" style="color:#4f4f4f"> x {{ sitem.date_unit }}개월</span>
            </div>
          </div>
        </div>

        <!-- 무료체험 기간 중 결제혜택 -->
        <template v-if="value.date_unit!==undefined && service.plan.trial && value.date_unit > 1">
          <div class="h8">무료체험 기간 중 결제혜택</div>
          <div class="item-box-wrapper">
            <div v-for="(item,idx) in option.event"  :key="`event-${idx}`"
                 class="item-box unselect" :class="{'item-box-selected': value && item.value === value.event}"
                 @click="value.event=item.value">
              <div class="body4-medium" style="margin-bottom:8px">{{ item.name }}</div>
              <div class="line-through">{{ item.org_price|currency }}</div>
              <div>
                <span v-if="item.discount_rate < 100"
                      class="price primary" style="margin-right:4px">{{ item.discount_rate }}%</span>
                <span><span class="price">{{ item.price|currencyNum }}</span>원</span>
              </div>
            </div>
          </div>
          <div style="margin-top:16px; text-align:center" v-if="value.event === 'android'"
               class="body5 sub3">앱포팅작업과 관련해서는 별도안내가 진행될 예정입니다.</div>
        </template>

        <!-- 호스팅 -->
        <template v-if="(((value.date_unit>1 && value.event!==undefined) || value.date_unit === 1) && service.plan.trial) ||
                        (value.date_unit !== undefined && !service.plan.trial)">
          <div class="h8">호스팅</div>
          <div class="item-box-wrapper">
            <div v-for="(item,idx) in option.hosting"  :key="`hosting-${idx}`"
                 class="item-box unselect" :class="{'item-box-selected': value && item.value === value.hosting}"
                 @click="value.hosting=item.value">
              <div class="body4-medium">{{ item.label }}</div>
              <div class="body5 sub3" style="margin:4px 0">{{ item.desc }}</div>
              <div><span class="price">{{ item.price|currencyNum }}</span>원/월</div>
            </div>
          </div>
          <div style="margin-top:16px; text-align:center"
               class="body5 sub3">서비스 구동을 위해 필수 옵션인 호스팅비가 플랜 주기에 따라 함께 결제 됩니다.</div>
        </template>
        <!-- 앱마켓 -->
        <template v-if="((value.date_unit>1 && value.event!==undefined) || value.date_unit === 1 )
                        && value.hosting!==undefined && apps.length>0">
          <div class="h8" style="margin-bottom:10px">앱마켓 (플랫폼 팩/플러그인)</div>
          <div class="body5 sub3" style="text-align: center;margin-bottom: 10px">
            추가했던 플랫폼 팩 / 플러그인 중 결제할 상품을 선택해주세요.</div>
          <div class="apps-wrapper">
            <div class="item-app unselect" v-for="(app, idx) in apps" :key="`app-${idx}`"
                 @click="app.checked = !app.checked">
              <div class="flex-between" style="width:100%;gap:20px">
                <div class="flex" style="flex:1">
                  <div class="check-box unselect" :class="{'checked': app.checked}">
                    <i class="ic-check material-icons">check</i>
                  </div>
                  <div style="word-break: keep-all">
                    <div class="subtitle7 sub">{{ app.name }}
                      <span v-if="app.products[0].plugin_range.indexOf('관리자')>-1"
                            class="tag-admin">관리자</span>
                    </div>
                    <div class="body5 sub3">{{ app.products[0].simple_desc }}</div>
                    <div style="margin-top:8px">
                      <span>
                        <span v-if="app.is_discount" class="primary" style="margin-right:4px">
                          <span v-if="app.discount_type === 0">{{ app.discount_rate }}%</span>
                          <span v-else-if="app.discount_type === 1">{{ app.discount_price|currency }}%</span>
                        </span>
                        <span class="price">{{ app.date_unit > 0 ? app.price * app.date_unit : app.price|currency }}</span>
                        <span v-if="app.date_unit>0">/{{ app.date_unit === 1 ? '월' : app.date_unit + '개월' }}</span>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="app-img-bg">
                  <template v-if="app.products[0].icon">
                    <img class="app-icon" :src="app.products[0].icon">
                  </template>
                  <template v-else>
                    <img class="app-img" :src="app.products[0].img">
                  </template>
                  <div class="icon-package" v-if="app.app_type === 'package'">
                    <svg width="17" height="18" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M17.0749 6.76655C17.0702 6.74458 17.0702 6.72185 17.0749 6.69988C17.0709 6.68065 17.0709 6.66078 17.0749 6.64155V6.56655L17.0249 6.44155C17.0046 6.40744 16.9793 6.37655 16.9499 6.34988L16.8749 6.28322H16.8333L13.5499 4.20822L10.4499 2.29155C10.3782 2.23466 10.2961 2.19221 10.2083 2.16655H10.1416C10.0671 2.15412 9.99109 2.15412 9.9166 2.16655H9.83327C9.73647 2.18796 9.64366 2.22452 9.55827 2.27488L3.33327 6.14988L3.25827 6.20822L3.18327 6.27488L3.09993 6.33322L3.05827 6.38322L3.00827 6.50822V6.58322V6.63322C3.00017 6.68848 3.00017 6.74462 3.00827 6.79988V14.0749C3.00798 14.2165 3.0438 14.3559 3.11233 14.4798C3.18086 14.6037 3.27984 14.7082 3.39993 14.7832L9.64993 18.6499L9.77493 18.6999H9.8416C9.98259 18.7446 10.134 18.7446 10.2749 18.6999H10.3416L10.4666 18.6499L16.6666 14.8415C16.7867 14.7665 16.8857 14.6621 16.9542 14.5381C17.0227 14.4142 17.0586 14.2748 17.0583 14.1332V6.85822C17.0583 6.85822 17.0749 6.79988 17.0749 6.76655ZM9.99993 3.97488L11.4833 4.89155L6.82493 7.77488L5.33327 6.85822L9.99993 3.97488ZM9.1666 16.4749L4.58327 13.6749V8.34988L9.1666 11.1832V16.4749ZM9.99993 9.71655L8.40827 8.75822L13.0666 5.86655L14.6666 6.85822L9.99993 9.71655ZM15.4166 13.6499L10.8333 16.4999V11.1832L15.4166 8.34988V13.6499Z" fill="#FFFFFF"/>
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="((value.date_unit>1 && value.event!==undefined) || value.date_unit === 1 )
                        && value.hosting!==undefined">
          <div class="h8" style="margin-bottom:10px">솔루션 이용 옵션</div>
          <div class="body5 sub3" style="text-align: center;margin-bottom: 10px">이용 중 발생하는 여러 운영 이슈를 다이렉트로 문의할 수 있는 창구 서비스입니다.</div>
          <div class="item-box-wrapper">
            <div v-for="(item,idx) in option.care_service"  :key="`hosting-${idx}`"
                 class="item-box unselect" :class="{'item-box-selected': value && item.value === value.care_service}"
                 @click="value.care_service=item.value">
              <div class="body4-medium">{{ item.label }}</div>
              <div class="flex-center body5 sub3" style="margin:4px 0;letter-spacing: -0.5px;white-space: pre-wrap; height: 40px">{{ item.desc }}</div>
              <div class="body4 sub"><span class="price">{{ item.price|currencyNum }}</span>원<span class="sub3">/월</span></div>
              <div class="divider"></div>
              <div class="body5 sub3" style="margin-bottom:4px">서버, 결제 관련 이슈 문의</div>
              <div class="subtitle6 sub2">최대 <span style="color:#ff5722">{{ item.hours }}</span>시간 내 답변</div>
              <div class="body5 sub3" style="margin:12px 0 4px 0">프론트엔드 관련 이슈 문의</div>
              <div class="subtitle6 sub2">최대 <span style="color:#ff5722">{{ item.days }}</span>일 내 답변</div>
            </div>
          </div>
          <div style="margin-top:16px; text-align:center"
               class="body5 sub3">* 이슈 해결 비용 별도로 청구됩니다.</div>
        </template>
        <template v-if="(((value.date_unit>1 && value.event!==undefined) || value.date_unit === 1) && service.plan.trial) ||
                        (value.date_unit !== undefined && !service.plan.trial)">
          <div class="h8" style="margin-bottom:16px">쿠폰선택</div>
          <div v-if="option.coupons && option.coupons.length>0" class="box-coupon">
              <div v-for="(coupon,cIdx) in option.coupons" :key="`coupon-${cIdx}`"
                   :class="`unselect item-radio${value.coupon === coupon.value ? '-selected' : ''}`" @click="clickCoupon(coupon)">
                <div class="radio-button"></div>
                <div class="body4 sub">{{ coupon.label }}</div>
              </div>
          </div>
          <div v-if="option.coupons && option.coupons.length === 0" class="box-no-coupon body4 sub3">사용 가능한 쿠폰이 없습니다.</div>
        </template>

      </div>
    </div>
    <div class="flex-justify bg-gray3"
         v-if="value.date_unit !== undefined && value.hosting !== undefined &&
              (((value.event !== undefined && value.date_unit>1) || value.date_unit === 1) && service.plan.trial) ||
              (value.event === undefined && !service.plan.trial) && totalPrice > 0">
      <div class="plan-content">
        <div class="h8">결제수단</div>
        <billing-form ref="billingForm" :service_id="service.id" style="text-align: left"></billing-form>

        <div class="h8">결제금액</div>
        <div class="box-account-price">
          <div v-for="(account, idx) in accounts" :key="`account-${idx}`"
               class="item-price">
            <div class="body4">{{ account.label }} <span v-if="account.date_unit_txt">({{ account.date_unit_txt }})</span></div>
            <div>
              <span class="price">{{ account.price|currencyNum }}</span>원<span class="sub3" v-if="account.date_unit>0">/{{ account.date_unit === 1 ? '월' : account.date_unit + '개월' }}</span>
            </div>
          </div>
        </div>
        <div class="box-total-price">
          <div class="subtitle5">총 결제금액</div>
          <div class="body2"><span class="h6 price">{{ totalPrice|currencyNum }}</span>원</div>
        </div>

        <!-- 업그레이드 -->
        <div v-if="remainPrice>0"
             class="body5 box-red flex-between">
          <div class="flex-align body3" style="gap:16px">
            <i class="material-icons-round">warning</i>
            <div>변경 전 플랜의 잔여금액은<br><b>이전 결제에서 부분 취소됩니다.</b></div>
          </div>
          <div class="h6">{{ remainPrice|currencyNum }}<span class="body2">원</span></div>
        </div>
        <!-- 다운그레이드 -->
        <div v-else-if="!service.plan.trial && !isUpgrade" class="body5 box-blue flex-align">
          <i class="material-icons-round">warning</i>
          <div>{{ nextDate }}에 결제가 시작되며 플랜주기별로 자동으로 갱신됩니다.<br>
            이후 변경된 플랜의 서비스 이용이 가능합니다.</div>
        </div>

        <ul class="body5 sub3 text-left">
          <li>{{ service.plan.trial || isUpgrade ? now : nextDate }}에 결제가 시작되며 플랜주기별로 자동으로 갱신됩니다.</li>
          <li>서비스 구동을 위해 필수 옵션인 호스팅비가  플랜 주기에 따라 함께 결제 됩니다</li>
          <li>계속 진행함으로써 본인이 만 19세 이상임을 확인하고,
            본 <a href="https://platformfy.co.kr/term" target="_blank">약관</a>에 동의함을 확인합니다.</li>
          <li>계속 진행하면 런치팩 서비스 약관에 동의하는 것으로 간주됩니다.
            <a href="https://platformfy.co.kr/privacy" target="_blank"
               style="margin-left:4px">개인정보처리방침</a></li>
        </ul>
        <div class="flex-center">
          <button class="button is-primary button-account"
                  @click="clickAccount">{{ service.plan.trial ? '결제하기' : '플랜 변경' }}</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';
  import BillingForm from "../../module/BillingForm";
  import ServiceAdminAPIMixin from "../../../mixins/ServiceAdminAPIMixin";
  import UserAPIMixin from "../../../mixins/UserAPIMixin";
  export default {
    name: "PlanChangeStep2",
    mixins: [
      ServiceAdminAPIMixin,
      UserAPIMixin
    ],
    components: {
      BillingForm
    },
    props: {
      service: {
        type: Object
      },
      selectedItem: {
        type: Object
      }
    },
    created() {
      this.now = moment().format('YYYY.MM.DD');
      if(!this.isCurrent(this.selectedItem)) {
        if(this.isUpgrade) {
          // 잔여 기간보다 짧은 기간 선택 못하게 처리
          this.selectedItem.subscriptions.forEach(item => {
            item.disabled = item.date_unit < this.remainMonth;
          });
          // 선택한 기간이 잔여기간보다 같거나 클 경우 선택
          if(this.selectedItem.date_unit >= this.remainMonth) {
            this.value.date_unit = this.selectedItem.date_unit;
          }
        } else {
          this.value.date_unit = this.selectedItem.date_unit;
        }
        if(this.value.date_unit === 1) {
          this.value.event = undefined;
        }
      }
      this.value.hosting = this.service.hosting;

      // 무료체험일 때만 앱마켓 내용 호출
      if(this.service.plan.trial) {
        this.getApps();
        this.value.care_service = 'BASIC';
      }
      this.getCoupons();
    },
    methods: {
      clickCoupon(coupon) {
        this.value.coupon = coupon.value;
      },
      getCoupons() {
        this.request.user.get('coupon').then(res => {
          let coupons = [{ label: '사용안함', value: 0 }].concat(
            res.data.filter(i => !i.used && !i.expired).map(i => {
              return {
                label: `[추천인 쿠폰] ${i.coupon.name} 쿠폰 (-50,000원)`,
                value: i.id
              }
            }));
          this.option.coupons = coupons.length > 1 ? coupons : [];
        });
      },
      isCurrent(item) {
        return item.date_unit === this.service.plan.date_unit &&
              this.selectedItem.membership === this.service.plan.membership &&
              !this.service.plan.trial;
      },
      clickAccount() {
        if(!this.validate()) {
          this.toast('카드 정보를 제대로 입력해주세요.');
          return;
        }

        // 제외 팩 플러그인
        let app_uninstall = this.apps.filter(i => !i.checked).map(i => i.id);
        let accounts = this.cloneItem(this.accounts);

        if(this.value.event === 'free') {
          accounts.push({
            type: 'free',
            label: '무료 1개월',
            price: 0,
            date_unit: 0
          })
        }

        let params = {
          trial: this.service.plan.trial,
          is_upgrade: this.isUpgrade,
          accounts: accounts,
          app_uninstall: app_uninstall,
          bill: this.$refs.billingForm.selectedBill,
          credit: this.$refs.billingForm.value,
          total_price: this.totalPrice,
          remain_price: this.remainPrice,
          remain: this.remain
        };
        this.$emit('next', params);
      },
      validate() {
        if(this.$refs.billingForm.selectedBill) {
          return true;
        }
        let valid = true;
        Object.keys(this.$refs.billingForm.value).forEach(key=>{
          if(this.$refs.billingForm.value[key] === '') {
            valid = false;
          }
        });

        return valid;
      },
      async getApps() {
        let res = await this.request.serviceAdmin.get(`/launchpack/v1/service/${this.service.id}/app_order?status=2`);
        this.apps = res.data.filter(i => ['package','plugin'].indexOf(i.app_type)>-1).map(i => {
          i.name = i.products[0].name;
          this.setAppPrice(i, i.date_unit);

          // 무료체험 중에, 앱마켓 선택한 팩 결제 주기 플랜과 동일하게 맞추기
          if(this.service.plan.trial) {
            if(i.app_type === 'package') {
              this.setAppPrice(i, this.value.date_unit);
            }
          }

          i.checked = true;
          return i;
        })
      },
      setAppPrice(item, date_unit) {
        item.date_unit = date_unit;
        let product = item.products[0];
        let sub = product.subscriptions.find(i => i.date_unit === date_unit);
        if(sub) {
          item.price = sub.price;
          item.discount_rate = sub.discount_rate;
          item.discount_type = 0;
          item.is_discount = sub.discount_rate > 0;
        } else {
          item.price = product.price;
          item.discount_type = product.discount_type;
          item.discount_rate = product.discount_rate;
          item.discount_price = product.discount_price;
          item.is_discount = product.is_discount;
        }
      },
      prev() {
        this.$emit('prev');
      },
      dateUnitTxt(item) {
        return `${item.date_unit}개월`;
      },
      clickDateUnit(item) {
        if(item.disabled || this.isCurrent(item)) {
          return;
        }
        if(item.date_unit === 1) {
          this.value.event = undefined;
        }
        this.value.date_unit = item.date_unit;
        // 무료체험 중에, 앱마켓 선택한 팩 결제 주기 플랜과 동일하게 맞추기
        this.apps.forEach(i => {
          if(this.service.plan.trial) {
            if(i.app_type === 'package') {
              this.setAppPrice(i, this.value.date_unit);
            }
          }
        })
      }
    },
    data() {
      return {
        now: '',
        value: {
          date_unit: undefined,
          event: undefined,
          hosting: undefined,
          care_service: undefined,
          coupon: 0
        },
        apps: [],
        option: {
          event: [
            {
              value: 'free',
              name: '무료 1개월 이용권 추가',
              org_price: 240000,
              price: 0,
              discount_rate: 100
            },
            {
              value: 'android',
              name: '앱포팅 및 등록 대행(Android)',
              org_price: 3000000,
              price: 540000,
              discount_rate: 82
            }
          ],
          hosting: [
            {
              label: 'BASIC',
              desc: '동시접속자 수 100 미만',
              price: 80000,
              value: 'BASIC'
            },
            {
              label: 'PREMIUM',
              desc: '동시접속자 수 100 ~ 500',
              price: 160000,
              value: 'PREMIUM'
            }
          ],
          care_service: [
            {
              label: 'BASIC',
              desc: '공식홈페이지 고객센터로 접수',
              price: 0,
              value: 'BASIC',
              hours: 72,
              days: 7
            },
            {
              label: 'FAST TRACK',
              desc: '이슈해결 무상작업 월 8시간 제공\n 어드민 1:1문의 창구 개설',
              price: 300000,
              value: 'FAST TRACK',
              hours: 24,
              days: 2
            }
          ],
          coupons: undefined
        }
      }
    },
    computed: {
      isUpgrade() {
        return (
          // 등급이 높거나
          (this.selectedItem.grade > this.service.plan.grade) ||
          // 같은 등급인데, 결제주기가 더 길어지는 경우
          (this.selectedItem.grade === this.service.plan.grade &&
            this.value.date_unit !== undefined &&
            this.value.date_unit > this.service.plan.date_unit)
        )
      },
      nextDate() {
        return this.isUpgrade ? moment().add('months', this.value.date_unit).format('YYYY.MM.DD') :
               moment(this.service.plan.next_date).format('YYYY.MM.DD');
      },
      remainMonth() {
        return this.isUpgrade ? this.service.plan.date_unit - moment().diff(moment(this.service.plan.use_start_date), 'months') : 0;
      },
      remainPrice() {
        if(this.service.plan.trial || !this.isUpgrade) {
          return 0;
        }
        const plan_start = moment(this.service.plan.use_start_date).format('YYYY-MM-DD');
        const plan_price = this.service.plan.price - (moment().diff(plan_start, 'days') *
          (this.service.plan.price / this.service.plan.date_unit / 30));

        let hosting_price = 0;
        if(this.service.hosting_info.use_start_date) {
          let hosting_start = moment(this.service.hosting_info.use_start_date).format('YYYY-MM-DD');
          hosting_price = this.service.hosting_info.price - (moment().diff(hosting_start, 'days') *
            (this.service.hosting_info.price / this.service.hosting_info.date_unit / 30));
        }
        return parseInt(plan_price + hosting_price);
      },
      remain() {
        if(this.service.plan.trial || !this.isUpgrade) {
          return undefined;
        }
        const plan_start = moment(this.service.plan.use_start_date).format('YYYY-MM-DD');
        const plan_price = this.service.plan.price - (moment().diff(plan_start, 'days') *
          (this.service.plan.price / this.service.plan.date_unit / 30));

        let hosting_price = 0;
        if(this.service.hosting_info.use_start_date) {
          let hosting_start = moment(this.service.hosting_info.use_start_date).format('YYYY-MM-DD');
          hosting_price = this.service.hosting_info.price - (moment().diff(hosting_start, 'days') *
            (this.service.hosting_info.price / this.service.hosting_info.date_unit / 30));
        }
        return {
          plan: {
            id: this.service.plan.id,
            price: parseInt(plan_price)
          },
          hosting: {
            id: this.service.hosting_info.id,
            price: parseInt(hosting_price)
          }
        }
      },
      accounts() {
        let result = [];
        if(this.value.date_unit===undefined) {
          return result;
        }
        let date_unit_txt = `${this.value.date_unit}개월`;
        // 플랜
        result.push({
          type: 'plan',
          label: `플랜 ${this.selectedItem.membership}`,
          name: this.selectedItem.membership,
          date_unit_txt: date_unit_txt,
          price: this.selectedItem.subscriptions.find(i => i.date_unit === this.value.date_unit).price * this.value.date_unit,
          date_unit: this.value.date_unit,
          grade: this.selectedItem.grade
        });
        // 호스팅
        if(this.value.hosting) {
          result.push({
            type: 'hosting',
            label: `호스팅 ${this.value.hosting}`,
            name: this.value.hosting,
            date_unit_txt: '1개월',
            price: this.option.hosting.find(i => i.value === this.value.hosting).price,
            date_unit: 1
          });
        }
        // 추가 팩 / 플러그인
        result = result.concat(this.apps.filter(i => i.checked).map(i => {
          return {
            type: i.app_type,
            id: i.id,
            label: {package: '추가 팩 ', plugin: '추가 플러그인 '}[i.app_type] + i.name,
            name: i.name,
            price: i.date_unit > 0 ? i.price * i.date_unit : i.price,
            date_unit: i.date_unit
          }
        }));
        // 호스팅
        if(this.value.care_service) {
          result.push({
            type: 'care_service',
            label: `솔루션 이용 옵션 ${this.value.care_service}`,
            name: this.value.care_service,
            date_unit_txt: '1개월',
            price: this.option.care_service.find(i => i.value === this.value.care_service).price,
            date_unit: 1
          });
        }
        // 앱포팅
        if(this.value.event === 'android') {
          result.push({
            type: 'android',
            label: '앱포팅(Android)',
            price: this.option.event.find(i => i.value === 'android').price,
            date_unit: 0
          })
        }
        // 쿠폰
        if(this.value.coupon) {
          result.push({
            type: 'coupon',
            label: '쿠폰',
            price: -50000,
            date_unit: 0,
            id: this.value.coupon
          })
        }

        // 부가세
        result.push({
          type: 'vat',
          label: '부가세(10%)',
          price: parseInt(result.reduce((a,c) => a + c.price, 0) / 10),
          date_unit: 0
        })
        return result;
      },
      totalPrice() {
        return this.accounts.reduce((a,c) => a + c.price, 0);
      }
    }
  }
</script>


<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .flex-justify
    width 100%
    display flex
    justify-content center

  .plan-change-step2-container
    color $main

    .header
      display grid
      grid-template-columns 88px 1fr 88px
      padding 32px 0 20px 0
      margin 0 60px
      border-bottom 1px solid $gray2

      .h5
        text-align center

    .plan-content
      width 480px
      padding 40px 0

      .h8
        margin-top 44px
        text-align center
        margin-bottom 16px
      .h8:first-child
        margin-top 0

    .event
      color #7B61FF
      font-size 14px
      line-height 22px
      text-align center
      margin-bottom 16px

      .tag-event
        background-color #7B61FF
        color white
        padding 2px 8px
        border-radius 50px
        font-size 12px
        line-height 14px
        font-weight 700

    .item-bar
      border-radius 4px
      border 1px solid $gray1
      //box-shadow 0 2px 4px 0 rgba(0, 0, 0, 0.05)
      padding 12px 16px
      display flex
      align-items center
      justify-content space-between
      margin-bottom 8px

    .item-bar-selected
      border 2px solid $primary

    .item-bar-disabled
      background-color $gray3
      cursor not-allowed
      .radio-button
        background-color $gray3

    .item-bar:last-child
      margin-bottom 0


    .radio-button
      width 16px
      height 16px
      border 1px solid #dddddd
      border-radius 100%
      background-color white
      margin-right 8px


    .item-radio-selected
    .item-bar-selected
      .radio-button
        width 16px
        height 16px
        border 2px solid $primary
        border-radius 100%
        background-color $primary
        box-shadow inset 0 0 0 1.5px rgb(255 255 255)
        margin-right 8px

    .current-bar
      background-color #F5F5F5
      cursor not-allowed
      .bar-name
        color #828282
    .tag-use
      background-color #7B61FF
      color white
      padding 0 4px
      font-size 12px
      font-weight 400
      line-height 18px
      border-radius 4px
      margin-left 8px

  .line-through
    color #aeaeae
    font-size 12px
    text-decoration line-through
  .price
    font-weight 700


  .item-box-wrapper
    display grid
    grid-template-columns repeat(2, 1fr)
    grid-gap 8px

  .item-box
    border-radius 4px
    border 1px solid $gray1
    box-shadow 0 2px 4px 0 rgba(0, 0, 0, 0.05)
    padding 20px
    text-align center
  .item-box-selected
    padding 19px
    border 2px solid $primary

  .apps-wrapper
    border 1px solid $gray1
    box-shadow 0 2px 4px 0 rgba(0, 0, 0, 0.05)
    border-radius 4px
    .item-app
      padding 12px 16px
      border-bottom 1px solid $gray1
      display flex
      align-items center
      justify-content space-between
      text-align left
    .item-app:last-child
      border-bottom 0
    .check-box
      margin-top 6px
      width 16px
      height 16px
      border-radius 4px
      border 1px solid #dddddd
      display flex
      align-items center
      justify-content center
      margin-right 12px
      .ic-check
        font-size 15px
        color white
    .checked
      border 1px solid $primary
      background-color $primary

    .tag-admin
      padding 2px 4px
      color $primary
      font-size 12px
      font-weight 500
      border-radius 4px
      margin-left 6px
      background-color $primary-light2
    .app-img-bg
      width 80px
      height 80px
      border-radius 12px
      background-color $gray3
      display flex
      align-items center
      justify-content center
      overflow hidden
      position relative
      .app-img
        width 80px
        height 80px
      .app-icon
        width 24px
        height 24px
      .icon-package
        position absolute
        bottom 10px
        left 10px
        background-color $primary
        border-radius 4px
        width 24px
        height 24px
        display flex
        align-items center
        justify-content center

  .bg-gray3
    background-color $gray3

  .box-account-price
    background-color white
    padding 6px 20px
    border-top-left-radius 8px
    border-top-right-radius 8px
    .item-price
      padding 10px 0
      border-bottom 1px solid $gray1
      display flex
      justify-content space-between
    .item-price:last-child
      border-bottom 0
  .box-total-price
    border-bottom-left-radius 8px
    border-bottom-right-radius 8px
    display flex
    justify-content space-between
    background-color #4f4f4f
    color white
    padding 16px 20px

  ul
    list-style disc
    padding-left 22px
    margin-top 16px

  .button-account
    width 242px
    height 48px !important
    margin-top 44px
    font-size 16px !important
    font-weight 500
    border-radius 8px

  a
    color #0063F7

  .box-red
  .box-blue
    padding 12px 16px
    gap 16px
    margin-top 16px
    text-align left
    border-radius 8px

  .box-red
    color $error
    background-color $error50
  .box-blue
    color #0063F7
    background-color #F2F7FF

  .divider
    width 100%
    height 1px
    background-color $gray1
    margin 12px 0

  .box-no-coupon
    text-align center
    background-color $gray3
    padding 10px
  .box-coupon
    border 1px solid $gray
    padding 20px 24px
    text-align left
    border-radius 4px
    display grid
    grid-row-gap 16px

    .item-radio
    .item-radio-selected
      display flex
      align-items center
      gap 8px
</style>
